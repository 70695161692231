<template>
  <div class="vx-row">
    <div class="vx-col w-full mb-base" v-if="is_filter">
      <vx-card title="Filter">
        <div class="vx-row mb-3">
          <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-3/4 w-full">
            <small class="ml-2">Status</small>
            <v-select v-model="filterData.current_status_id" :options="statuses" name="current_status_id" label="name" :reduce="e => e.id" @input='changeStatus()'></v-select>
          </div>
        </div>
        <div class="vx-col w-full flex mt-3">
          <vs-button color="warning" @click="resetFilter()">Reset</vs-button>
        </div>
      </vx-card>
    </div>
    <div class="vx-col w-full mb-base">
      <vx-card title="Shipments">
        <vs-table :sst="true" search @search="handleSearch" :data="data.data" @sort="handleSort" class="mb-3" >
          <template slot="header">
            <vs-button color="primary" @click="is_filter = !is_filter">Filter</vs-button>
          </template>
          <template slot="thead">
            <vs-th sort-key="name">Shipment No.</vs-th>
            <vs-th sort-key="job_assign_time">Assigned At</vs-th>
            <vs-th sort-key="unit_ready_time">Unit Ready At</vs-th>
            <vs-th sort-key="route">Route</vs-th>
            <vs-th sort-key="route">Vehicle</vs-th>
            <vs-th sort-key="type">Shipping By</vs-th>
            <vs-th sort-key="vehicle_type">Status</vs-th>
            <vs-th sort-key="created_at"></vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="tr.code">
                <router-link :to="{name:'shipments-show', params:{id: tr.id}}">{{tr.code}}</router-link>
              </vs-td>
              <vs-td label='assigned at' :data="tr.job_assign_time">{{tr.job_assign_time}}</vs-td>
              <vs-td label='unit ready at' :data="tr.unit_ready_time">{{tr.unit_ready_time}}</vs-td>
              <vs-td  label='route' :data="tr.route">{{tr.route}}</vs-td>
              <vs-td  label='vehicle' :data="tr.police_no">{{tr.police_no}}</vs-td>
              <vs-td  label='shipping by' :data="tr.route">{{tr.shipping_by == 'vendor' ? 'Vendor : ' + tr.vendor_name  : ''}}{{tr.shipping_by == 'vendor' ? ',' : ''}} {{ tr.driver_name }}</vs-td>
              <vs-td  label='status' :data="tr.status_name">{{tr.status_name}}</vs-td>
              <vs-td :data="tr.id" width="10">
                <div class="flex">
                  <vs-button size="small" type="line" :to="{name:'shipments-edit', params:{id: tr.id}}" color="success" icon-pack="feather" icon="icon-edit"></vs-button>
                  <vs-button size="small" type="line" :to="{name:'shipments-show', params:{id: tr.id}}" icon-pack="feather" icon="icon-folder"></vs-button>
                </div>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-pagination :total="data.last_page" v-model="currentPage" class="mt-5"></vs-pagination>
      </vx-card>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import vSelect from 'vue-select'

export default {
  props : ['job_order_id', 'driver_id', 'vehicle_id'],
  components:{
    vSelect
  },
  data(){
    return {
      filterData : {},
      is_filter : false,
      idDelete: null,
      currentPage: 1,
      search:null,
      sortKey:null,
      sortVal:null
    }
  },
  watch:{
    search(){
      this.currentPage=1
      this.getData()
    },
    currentPage(){
      this.getData()
    }
  },
  computed:{
    ...mapState({
      statuses: state => state.delivery_orders.all,
      data: state => state.delivery_orders.rows
    })
  },
  methods:{
    ...mapActions({
      dispatchAllStatus: 'delivery_orders/all_status',
      dispatchIndex: 'delivery_orders/index',
      dispatchDestroy: 'delivery_orders/destroy'
    }),
    async getData(){
      let payload = {
        vehicle_id: this.vehicle_id,
        driver_id: this.driver_id,
        job_order_id: this.job_order_id,
        page: this.currentPage,
        search: this.search,
        sortKey: this.sortKey,
        sortVal: this.sortVal
      }
      payload = Object.assign(payload, this.filterData)
      await this.dispatchIndex(payload)
    },
    async confirmDelete(){
      try {
        await this.dispatchDestroy(this.idDelete)
        this.getData()
        this.$vs.notify({
          title: `Success`,
          text: `Your data has been deleted successfully`,
          color: `primary`
        })
      } catch (error) {
        this.$vs.notify({
          title: `Oops!`,
          text: `Looks like something went wrong. please try again later (${error.data.message})`,
          color: `danger`
        })
      }
    },
    deletes(id){
      this.idDelete = id
      this.$vs.dialog({
        type: `confirm`,
        color: `danger`,
        title: `Are you sure ?`,
        text: `Deleted data can no longer be restored`,
        accept: this.confirmDelete
      })
    },
    handleSearch(val){
      this.search = val
    },
    handleSort(key,val){
      this.sortKey = key
      this.sortVal = val
      this.getData()
    },
    async changeStatus() {
      await this.getData()
    },
    async resetFilter() {
      this.filterData = {}
      await this.getData()
    }
  },
  async mounted(){
    await this.dispatchAllStatus();
    await this.getData()
  }
}
</script>

<style>

</style>